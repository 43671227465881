export default [
    {
      path: "/packages",
      name: "packages",
      component: () => import("@/views/Packages/packages.vue"),
      meta: {
        pageTitle: "Packages",
        breadcrumb: [
          {
            text: "Packages",
            active: true,
          },
        ],
      },
    },
  
    
  ];
  