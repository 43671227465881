export default [
    {
      path: "/gallery",
      name: "gallery",
      component: () => import("@/views/Gallery/galeries.vue"),
      meta: {
        pageTitle: "Gallery",
        breadcrumb: [
          {
            text: "Gallery",
            active: true,
          },
        ],
      },
    },
  
    
  ];
  